import { FormGroup } from '@angular/forms';
import { ProfilEvaluateur } from '@core-evaluateur/profil.guard';
import { CHOSEN_PROFILE_KEY } from '@features-evaluateur/acces-evaluateur/evaluateur-choice/evaluateur-choice.component';
import {
  Aap,
  Comite,
  DocumentAapModel,
  DocumentConfig,
  EnumActivite,
  EnumCriteresReferentielOptions,
  EnumNotationCreatorType,
  EnumNotationEtape,
  EnumPermissionUtilisateur,
  EnumPhaseType,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumRoleContact,
  EnumRolePartenaire,
  EnumRoleStructure,
  EnumScope,
  EnumStatutComite,
  EnumTypeWorkflow,
  EnumUserGroup,
  Evaluateur,
  IntervenantRoles,
  MoveDocumentConfig,
  PermissionUtils,
  Projet,
  Structure,
  Utilisateur,
} from '@shared-ui';

export class SharedFunction {
  rl = false;
  rp = false;

  getProjectEtapeName(projet: Projet): EnumProjetEtape | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].nom;
  }

  getProjectEtapeStatut(projet: Projet): EnumProjetStatut | null {
    if (projet?.etapes == null) {
      return null;
    }
    return projet?.etapes[projet?.etapes.length - 1].statut;
  }

  isUserGroup(user: Utilisateur): boolean {
    return user?.groupe == EnumUserGroup.PXL_USER_GROUPE;
  }

  isUserInno(user: Utilisateur): boolean {
    return user?.groupe == EnumUserGroup.PXL_USERINNO_GROUPE;
  }

  isUserDar(user: Utilisateur): boolean {
    return user?.groupe === EnumUserGroup.PXL_USERDAR_GROUPE;
  }

  isProjectUpdatableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_WRITE, projet);
  }

  isProjectReadableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_READ, projet);
  }

  isProjectGrilleImpactsUpdatableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_GRILLE_IMPACTS_WRITE, projet);
  }

  isProjectGrilleImpactsReadableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_GRILLE_IMPACTS_READ, projet);
  }

  isDocumentProjectUpdatableByUser(projet: Projet, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DOCUMENT_WRITE, projet) &&
      this.isUserAffectedToProjectActivity(projet, user)
    );
  }

  isDocumentProjectReadableByUser(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DOCUMENT_READ, projet);
  }

  isStructureUpdatableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return this.isProjectUpdatableByUser(projet, user) && !structure.closed;
  }

  isStructureReadableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return this.isProjectReadableByUser(projet, user) && !structure.closed;
  }

  isStructureGrilleImpactsReadableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_GRILLE_IMPACTS_READ, projet) && !structure.closed
    );
  }

  isStructureGrilleImpactsUpdatableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_GRILLE_IMPACTS_WRITE, projet) && !structure.closed
    );
  }

  isStructurePrevisionsEconomiquesReadableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_PREVISIONS_ECONOMIQUES_READ, projet) &&
      !structure.closed
    );
  }

  isStructurePrevisionsEconomiquesUpdatableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_PREVISIONS_ECONOMIQUES_WRITE, projet) &&
      !structure.closed
    );
  }

  isDocumentStructureUpdatableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_DOCUMENT_WRITE, projet) &&
      this.isUserAffectedToProjectActivity(projet, user) &&
      !structure.closed
    );
  }

  isDocumentStructureReadableByUser(projet: Projet, structure: Structure, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_DOCUMENT_READ, projet) && !structure.closed;
  }

  isUserAffectedToWriteNotation(utilisateur: Utilisateur, projet: Projet, activite: EnumActivite): boolean {
    return (
      this.isUserAffectedToOneOfProjectActivity(projet, utilisateur, activite) &&
      PermissionUtils.hasPermissionOnProjet(
        utilisateur,
        activite === EnumActivite.OPPORTUNITE
          ? EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE
          : EnumPermissionUtilisateur.PROJET_AUDITION_WRITE,
        projet
      )
    );
  }

  canUserEvaluateProject(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteNotation(user, projet, EnumNotationEtape.EVALUATION, EnumNotationCreatorType.OWN);
  }

  canUserAuditProject(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteNotation(user, projet, EnumNotationEtape.AUDITION, EnumNotationCreatorType.OWN);
  }

  canUserNoteProjectInstruction(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteNotation(user, projet, EnumNotationEtape.INSTRUCTION, EnumNotationCreatorType.OWN);
  }

  canUserReadEvaluations(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasAnyPermissionOnProjet(
      user,
      [
        EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_READ,
        EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_EVALUATEUR_READ,
        EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_INSTRUCTEUR_READ,
      ],
      projet
    );
  }

  canUserReadAuditions(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasAnyPermissionOnProjet(
      user,
      [
        EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_READ,
        EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_EVALUATEUR_READ,
        EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_INSTRUCTEUR_READ,
      ],
      projet
    );
  }

  canUserReadNotation(user: Utilisateur, projet: Projet, etape: EnumNotationEtape, notationCreatorType: EnumNotationCreatorType): boolean {
    const permission = READ_PERMS_MAP[notationCreatorType]?.[etape];
    return permission ? PermissionUtils.hasPermissionOnProjet(user, permission, projet) : false;
  }

  canUserWriteNotation(user: Utilisateur, projet: Projet, etape: EnumNotationEtape, notationCreatorType: EnumNotationCreatorType): boolean {
    const permission = WRITE_PERMS_MAP[notationCreatorType]?.[etape];
    return permission ? PermissionUtils.hasPermissionOnProjet(user, permission, projet) : false;
  }

  canUserReadDecision(user: Utilisateur, projet: Projet, etape: EnumNotationEtape): boolean {
    switch (etape) {
      case EnumNotationEtape.AUDITION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_AUDITION_READ, projet);
      case EnumNotationEtape.EVALUATION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_EVALUATION_READ, projet);
      case EnumNotationEtape.INSTRUCTION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_INSTRUCTION_READ, projet);
      default:
        return false;
    }
  }

  canUserWriteDecision(user: Utilisateur, projet: Projet, etape: EnumNotationEtape): boolean {
    switch (etape) {
      case EnumNotationEtape.AUDITION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_AUDITION_WRITE, projet);
      case EnumNotationEtape.EVALUATION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_EVALUATION_WRITE, projet);
      case EnumNotationEtape.INSTRUCTION:
        return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_INSTRUCTION_WRITE, projet);

      default:
        return false;
    }
  }

  canUserReadSynthese(user: Utilisateur, projet: Projet, etape: EnumNotationEtape): boolean {
    const perm =
      etape === EnumNotationEtape.AUDITION
        ? EnumPermissionUtilisateur.PROJET_SYNTHESE_AUDITION_READ
        : EnumPermissionUtilisateur.PROJET_SYNTHESE_EVALUATION_READ;
    return PermissionUtils.hasPermissionOnProjet(user, perm, projet);
  }

  canUserWriteSynthese(user: Utilisateur, projet: Projet, etape: EnumNotationEtape): boolean {
    const perm =
      etape === EnumNotationEtape.AUDITION
        ? EnumPermissionUtilisateur.PROJET_SYNTHESE_AUDITION_WRITE
        : EnumPermissionUtilisateur.PROJET_SYNTHESE_EVALUATION_WRITE;
    return PermissionUtils.hasPermissionOnProjet(user, perm, projet);
  }

  isUserAffectedToProjectActivity(projet: Projet, user: Utilisateur): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur => instructeur.matricule === user.matricule && instructeur.actif && instructeur.activites.length > 0
      ) != null
    );
  }

  isAffectableUserAffectedToOneOfProjectActivity(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    if (!PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.AUTOAFFECTATION_PROJET, projet)) {
      return true;
    }
    return this.isUserAffectedToOneOfProjectActivity(projet, user, activite);
  }

  isUserAffectedToOneOfProjectActivity(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite)
      ) != null
    );
  }

  isEvaluateurAffectedToOneOfProjectActivity(
    projet: Projet,
    evaluateurs: Evaluateur[],
    user: Utilisateur,
    activite: EnumActivite
  ): boolean {
    return (
      evaluateurs?.find(
        evaluateur =>
          evaluateur.matricule === user.matricule &&
          evaluateur.listAffectationProjets.length > 0 &&
          evaluateur.listAffectationProjets.find(
            affectationProjet => affectationProjet.id === projet.id && affectationProjet.notationEtapes.includes(activite)
          )
      ) != null
    );
  }

  canUserReadVerrouillage(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_VERROUILLAGE_READ, projet);
  }

  canUserWriteVerrouillage(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_VERROUILLAGE_WRITE, EnumActivite.VERROUILLAGE);
  }

  canUserReadEligibilite(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_ELIGIBILITE_READ, projet);
  }

  canUserWriteEligibilite(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_ELIGIBILITE_WRITE, EnumActivite.ELIGIBILITE);
  }

  canUserReadContractualisation(user: Utilisateur, projet?: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_READ, projet);
  }

  canUserWriteContractualisation(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(
      user,
      projet,
      EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_WRITE,
      EnumActivite.CONTRACTUALISATION
    );
  }
  canUserWriteEditionRelectureContratDonneeStructure(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(
      user,
      EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_EDITION_RELECTURE_DONNEES_STRUCTURE_WRITE,
      projet
    );
  }

  canUserReadAnalyseCompletude(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_COMPLETUDE_READ, projet);
  }

  canUserReadInstruction(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, projet);
  }

  canUserReadInstructionControle(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_CONTROLE_READ, projet);
  }

  canUserWriteInstruction(user: Utilisateur, projet: Projet): boolean {
    return this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, EnumActivite.INSTRUCTION);
  }

  canUserReadSelection(user: Utilisateur, projet: Projet): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DECISION_INSTRUCTION_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_SYNTHESE_INSTRUCTION_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_PROPOSITION_FINANCEMENT_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_RAPPORT_EXPERTISE_READ, projet)
    );
  }

  canUserWriteInstructionCompletude(user: Utilisateur, projet: Projet): boolean {
    return (
      this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_COMPLETUDE_WRITE, EnumActivite.INSTRUCTION) ||
      this.canUserWriteActivity(user, projet, EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE, EnumActivite.INSTRUCTION)
    );
  }

  canUserReadInstructionIlabEval(user: Utilisateur, ilab: boolean, projet: Projet, activite: EnumActivite): boolean {
    return (
      ilab &&
      (this.isUserAffectedToOneOfProjectActivity(projet, user, activite) ||
        PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ, projet))
    );
  }

  canUserWriteActivity(user: Utilisateur, projet: Projet, permissionWrite: EnumPermissionUtilisateur, activite: EnumActivite): boolean {
    if (this.isUserAffectedToOneOfProjectActivity(projet, user, activite)) {
      return PermissionUtils.hasPermissionOnProjet(user, permissionWrite, projet);
    }
    return false;
  }

  canUserEditComite(user: Utilisateur, comite: Comite): boolean {
    if (comite.statut === EnumStatutComite.CLOTURE) {
      return false;
    }
    return (
      comite?.auteur?.matricule === user?.matricule || // créateur
      this.isUserAffectedToComite(user, comite) // auto affecté
    );
  }

  canUserReadComites(user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermission(user, EnumPermissionUtilisateur.COMITE_READ) &&
      localStorage.getItem(CHOSEN_PROFILE_KEY) !== ProfilEvaluateur.EVALUATEUR_EXTERNE
    );
  }

  canUserWriteRapportExpertise(user: Utilisateur, projet: Projet) {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_RAPPORT_EXPERTISE_WRITE, projet);
  }

  isUserAffectedToComite(user: Utilisateur, comite?: Comite): boolean {
    return comite?.instructeurs?.find(instructeur => instructeur.matricule === user.matricule && instructeur.actif) != null;
  }

  isUserCreatorOfComite(user: Utilisateur, comite?: Comite): boolean {
    return comite?.auteur?.matricule === user?.matricule;
  }

  isStepStatutReached(statut: EnumProjetStatut, projet: Projet): boolean {
    return !!projet.etapes?.filter(step => step.statut === statut)[0];
  }

  /* Vérifie si les structures sont complètes
   * */
  checkIncompleteCompanies(structures: Structure[], projet: Projet, aap: Aap): Structure[] {
    return structures.filter(structure => {
      return this.checkIncompleteCompany(structure, projet, aap);
    });
  }

  /* Vérifie si la structure est complète
   * */
  checkIncompleteCompany(structure: Structure, projet: Projet, aap: Aap): boolean {
    if (structure.closed) {
      return false;
    }

    if (
      (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime && !structure.budgetPreDepot) ||
      (this.getProjectEtapeName(projet) === EnumProjetEtape.DEPOT && !structure.budgetDepot)
    ) {
      return true;
    }

    if (!structure.adresse || !structure.adresse?.cp) {
      return true;
    }

    if (!structure?.lieuRD?.raisonSocial && structure.role !== EnumRoleStructure.MANDATAIRE) {
      return true;
    }

    return this.checkIncompleteContacts(structure);
  }

  checkIncompleteContacts(structure: Structure): boolean {
    if (structure.role === EnumRoleStructure.MANDATAIRE) {
      if (structure.contacts?.length > 0) {
        for (const contact of structure.contacts) {
          if (!contact.nom || !contact.prenom || !contact.telephone || !contact.email) {
            return true;
          }
        }
      } else {
        return true;
      }

      // return false si contact complet
      return false;
    } else {
      return this.checkMissingRlRp(structure);
    }
  }

  checkMissingRlRp(structure: Structure) {
    let rl = false;
    let rp = false;
    return !structure.contacts?.some(contact => {
      if (!contact.nom || !contact.prenom || !contact.telephone || !contact.email) {
        return false;
      }
      if (contact.roles?.length > 0) {
        contact.roles.forEach(role => {
          if (role === (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()] && contact.paysNaissance) {
            rl = true;
          }
        });

        if (contact.roles.indexOf((EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1) {
          rp = true;
        }
      }
      return rl && rp;
    });
  }

  /*
   * Vérifie si les informations du projet sont remplies
   * */
  checkProjetInfo(projet: Projet): boolean {
    return projet.nom == null && projet.description == null;
  }

  /*
   * Vérifie si le budget du projet est remplie
   * */
  checkBudgetInfo(projet: Projet): boolean {
    return projet.budget == null;
  }

  isEvalPoleLabellisateur(evaluateur: Evaluateur, projet: Projet): boolean {
    return (
      evaluateur?.listAffectationProjets != null &&
      evaluateur?.listAffectationProjets.find(
        affectation => affectation.id === projet.id && affectation.utilisateurRole === 'POLE_LABELLISATEUR'
      )
    );
  }

  isEvaluateurInstructeurExterne(evaluateur: Evaluateur): boolean {
    return [EnumRolePartenaire.INSTRUCTEUR_EXTERNE, EnumRolePartenaire.INSTRUCTEUR_VALIDEUR].includes(evaluateur?.type);
  }

  isAutoAffectableOnAap(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.AUTOAFFECTATION_PROJET, aap);
  }

  isAutoAffectableToProjet(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.AUTOAFFECTATION_PROJET, projet);
  }

  canUserExtractAapProjets(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.EXTRACTION_PROJET_PARTENAIRE, aap);
  }

  canUserReadDemandeCorrection(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDECORRECTION_READ, projet);
  }

  canUserWriteDemandeCorrection(projet: Projet, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDECORRECTION_WRITE, projet) &&
      this.isUserAffectedToProjectActivity(projet, user)
    );
  }

  canUserReadDemandeComplement(projet: Projet, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDE_COMPLEMENT_READ, projet);
  }

  canUserWriteDemandeComplement(projet: Projet, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_DEMANDE_COMPLEMENT_WRITE, projet) &&
      this.isUserAffectedToProjectActivity(projet, user)
    );
  }

  canUserReadInstructionBDD(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_BASE_DONNEES_BUDGETAIRE_READ, projet);
  }

  canUserUpdateWorkflow(projet: Projet, aap: Aap, user: Utilisateur): boolean {
    return (
      (aap.typeWKF == EnumTypeWorkflow.WKF1 &&
        PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.WKF_PREDEPOT, projet)) ||
      (aap.typeWKF == EnumTypeWorkflow.WKF2 && PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.WKF_DEPOT, projet))
    );
  }
  canEvaluateurFilterProjects(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.PROJET_FILTERS, aap);
  }

  canInstructeurAffectEvaluateur(aap: Aap, user: Utilisateur): boolean {
    return PermissionUtils.hasPermissionOnAap(user, EnumPermissionUtilisateur.AFFECTATION_EVALUATEUR_PROJET, aap);
  }

  canInstructeurDownoaldArchive(user: Utilisateur): boolean {
    return (
      user?.perms.some(perm => perm.name === EnumPermissionUtilisateur.PROJET_ARCHIVE_DOWNLOAD) || this.canUserReadAtLeastOneProject(user)
    );
  }

  canAccessProjet(projet: Projet, user: Utilisateur): boolean {
    return (
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_GRILLE_IMPACTS_READ, projet) ||
      PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.STRUCTURE_PREVISIONS_ECONOMIQUES_READ, projet)
    );
  }
  /**
   *
   * Vérifie si le responsable projet des contacts de la structure est le même que le responsable projet déclarer dans les membres d'équipe
   * */
  checkEquipeAndContactHasValidResponsable(structure: Structure): boolean {
    if (!structure.contacts?.length || !structure.equipe?.length) {
      return true;
    }
    const responsableContact = structure.contacts.find(
      contact => contact.roles?.indexOf((EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()]) !== -1
    );
    const responsableEquipe = structure.equipe.find(equipe => !!equipe.identite?.responsableProjet);
    if (!responsableContact || !responsableEquipe) {
      return true;
    }
    return (
      responsableContact.nom?.toLocaleLowerCase() === responsableEquipe.identite?.nom?.toLocaleLowerCase() &&
      responsableContact.genre?.toLocaleLowerCase() === responsableEquipe.identite?.genre?.toLocaleLowerCase() &&
      responsableContact.prenom?.toLocaleLowerCase() === responsableEquipe.identite?.prenom?.toLocaleLowerCase()
    );
  }

  isDepotOrPreDepot(projet: Projet): boolean {
    const etape = this.getProjectEtapeName(projet);
    return etape === EnumProjetEtape.PRE_DEPOT || etape === EnumProjetEtape.DEPOT;
  }

  canUserActionButton(projet: Projet, user: Utilisateur): boolean {
    // TODO: Ajouter les permissions liées aux autres actions au fur et a mesure sur le menu action avec des OR, pour l'instant j'ai ajouté que PROJET_DEMANDE_COMPLEMENT_WRITE
    return this.canUserWriteDemandeComplement(projet, user) || this.canUserWriteDemandeCorrection(projet, user);
  }

  getBudgetProjet(projet: Projet, aap: Aap) {
    const structureList = projet.structures.filter(structure => structure.role !== EnumRoleStructure.MANDATAIRE && !structure.closed);

    let declaredBudgetSum = 0;
    structureList.forEach(structure => {
      if (this.getProjectEtapeName(projet) === EnumProjetEtape.PRE_DEPOT && !aap?.budgetEstime) {
        declaredBudgetSum += structure.budgetPreDepot?.montant ? Number(structure.budgetPreDepot.montant) : 0;
      } else if (this.getProjectEtapeName(projet) !== EnumProjetEtape.PRE_DEPOT) {
        declaredBudgetSum += structure.budgetDepot?.montant ? Number(structure.budgetDepot.montant) : 0;
      }
    });
    return JSON.stringify(declaredBudgetSum);
  }

  aapHasSelectedCritereEvaluation(aap: Aap, phase: EnumCriteresReferentielOptions): boolean {
    return aap?.criteresEvaluations?.length > 0 && !!aap.criteresEvaluations.find(crit => crit.phases.includes(phase));
  }

  getNotationType(aap: Aap, typeNotation: string) {
    if (aap?.notationEvaluationTypeNote && typeNotation === EnumPhaseType.EVALUATION.toString()) {
      return aap.notationEvaluationTypeNote;
    }
    if (aap?.notationAuditionTypeNote && typeNotation === EnumPhaseType.AUDITION.toString()) {
      return aap.notationAuditionTypeNote;
    }
    if (aap?.notationInstructionTypeNote && typeNotation === EnumPhaseType.INSTRUCTION.toString()) {
      return aap.notationInstructionTypeNote;
    }
  }

  getDocStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter((document: any) => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE
      ) {
        const roleMatching = document.roleStructures.filter((role: EnumRoleStructure) => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter((type: string) => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  getDocActioStructureAap(aap: Aap, structure: Structure, projet: Projet): DocumentAapModel[] {
    return aap.documentAaps?.filter((document: any) => {
      if (
        document.etapes.includes(this.getProjectEtapeName(projet)) &&
        document.typePartenaires?.includes(projet.partenaireType) &&
        document.scope === EnumScope.STRUCTURE_ACTIONARIAL
      ) {
        const roleMatching = document.roleStructures.filter((role: EnumRoleStructure) => {
          return structure.role === role;
        });
        const typeStructMatching = document.typeStructures.filter((type: string) => {
          return structure.typeStructure === type;
        });

        if (roleMatching.length > 0 && typeStructMatching.length > 0) {
          return document;
        }
      }
    });
  }

  isDisabledFormValid(form: FormGroup): boolean {
    const wasDisabled = form.disabled;
    form.enable();
    const formValid = form.valid;
    if (wasDisabled) {
      form.disable();
    }
    return formValid;
  }

  isDemandeRectificationExist(structure: Structure): boolean {
    return structure && structure.adresse?.demandeRectification != null;
  }

  isDemandeRectificationTreated(structure: Structure): boolean {
    return Boolean(structure && structure.adresse?.demandeRectification && structure.adresse.demandeRectification.demandeTraitee);
  }

  canUserWriteInterventionPI(user: Utilisateur, projet: Projet): boolean {
    return (
      this.canUserWriteActivity(
        user,
        projet,
        EnumPermissionUtilisateur.PROJET_INSTRUCTION_INTERVENTION_PI_WRITE,
        EnumActivite.INSTRUCTION
      ) && this.isUserAffectedToOneOfProjectInstructionAsIntervenantPI(projet, user, EnumActivite.INSTRUCTION)
    );
  }

  isUserAffectedToOneOfProjectInstructionAsIntervenantPI(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite && a.intervenantPI)
      ) != null
    );
  }

  canUserManageInterventionPI(user: Utilisateur, projet: Projet): boolean {
    return (
      this.canUserWriteActivity(
        user,
        projet,
        EnumPermissionUtilisateur.PROJET_INSTRUCTION_INTERVENTION_PI_WRITE,
        EnumActivite.INSTRUCTION
      ) && this.isUserAffectedToOneOfProjectInstructionAndNotAsIntervenantPI(projet, user, EnumActivite.INSTRUCTION)
    );
  }

  canUserReadInterventionPI(user: Utilisateur, projet: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(user, EnumPermissionUtilisateur.PROJET_INSTRUCTION_INTERVENTION_PI_READ, projet);
  }

  isUserAffectedToOneOfProjectInstructionAndNotAsIntervenantPI(projet: Projet, user: Utilisateur, activite: EnumActivite): boolean {
    return (
      projet?.instructeurs?.find(
        instructeur =>
          instructeur.matricule === user.matricule &&
          instructeur.actif &&
          instructeur.activites.length > 0 &&
          instructeur.activites.find(a => a.nomActivite === activite && !a.intervenantPI)
      ) != null
    );
  }

  getMoveDocumentConfig(aap: Aap, projet: Projet): MoveDocumentConfig {
    return {
      structures: projet.structures.map(structure => ({
        id: structure.id,
        raisonSociale: structure.raisonSocial,
      })),
      documents: this.getDocumentConfigs(aap, projet),
    };
  }

  private getDocumentConfigs(aap: Aap, projet: Projet): DocumentConfig[] {
    const etapeProjet = this.getProjectEtapeName(projet) as EnumProjetEtape;
    const documentsProjet: DocumentConfig[] = aap.documentAaps
      ?.filter(
        document =>
          document.scope === EnumScope.PROJET &&
          document.etapes.includes(etapeProjet) &&
          document.typePartenaires?.includes(projet.partenaireType)
      )
      .map(document => ({
        nomDocument: document.nomDocument,
        scope: EnumScope.PROJET,
        mediaTypes: document.mediaTypes,
        connaissanceClient: document.connaissanceClient,
      }))
      .sort((a, b) => (a.nomDocument > b.nomDocument ? 1 : -1));

    return documentsProjet.concat(
      projet.structures.flatMap(structure =>
        aap.documentAaps
          ?.filter(
            (docAap: any) =>
              [EnumScope.STRUCTURE, EnumScope.STRUCTURE_ACTIONARIAL].includes(docAap.scope) &&
              docAap.etapes.includes(etapeProjet) &&
              docAap.typePartenaires?.includes(projet.partenaireType) &&
              docAap.roleStructures?.includes(structure.role) &&
              docAap.typeStructures.includes(structure.typeStructure)
          )
          .map(document => ({
            nomDocument: document.nomDocument,
            scope: document.scope,
            mediaTypes: document.mediaTypes,
            connaissanceClient: document.connaissanceClient,
            structureId: structure.id,
          }))
      )
    );
  }

  canUserAutoAffectActivity(
    user: Utilisateur,
    projet: Projet,
    permissionWrite: EnumPermissionUtilisateur,
    activite: EnumActivite
  ): boolean {
    const instructeur = projet?.instructeurs?.find(ins => ins?.matricule === user?.matricule);
    const isInstructeurAssignedToActivite = instructeur?.actif && instructeur?.activites?.some(a => a.nomActivite === activite);
    return Boolean(isInstructeurAssignedToActivite && PermissionUtils.hasPermissionOnProjet(user, permissionWrite, projet));
  }

  isEvaluateurExpertExterne(evaluateur: Evaluateur): boolean {
    return evaluateur?.listAffectationProjets?.some(affectation => affectation?.utilisateurRole === IntervenantRoles.EXPERT_EXTERNE);
  }

  canUserReadAtLeastOneProject(user: Utilisateur) {
    return user?.perms?.some(perm => perm.name === EnumPermissionUtilisateur.PROJET_READ);
  }

  canUserReadProjetContractualisationProcedureSignature(user: Utilisateur, projet?: Projet): boolean {
    return PermissionUtils.hasPermissionOnProjet(
      user,
      EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_PROCEDURE_SIGNATURE_READ,
      projet
    );
  }
}

const READ_PERMS_MAP = {
  [EnumNotationCreatorType.EVALUATEUR]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_EVALUATEUR_READ,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_EVALUATEUR_READ,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ,
  },
  [EnumNotationCreatorType.INSTRUCTEUR]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_INSTRUCTEUR_READ,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_INSTRUCTEUR_READ,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ,
  },
  [EnumNotationCreatorType.OWN]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_READ,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_READ,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_READ,
  },
};

const WRITE_PERMS_MAP = {
  [EnumNotationCreatorType.EVALUATEUR]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_EVALUATEUR_WRITE,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_EVALUATEUR_WRITE,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
  },
  [EnumNotationCreatorType.INSTRUCTEUR]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_INSTRUCTEUR_WRITE,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_INSTRUCTEUR_WRITE,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
  },
  [EnumNotationCreatorType.OWN]: {
    [EnumNotationEtape.EVALUATION]: EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_WRITE,
    [EnumNotationEtape.AUDITION]: EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_WRITE,
    [EnumNotationEtape.INSTRUCTION]: EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
  },
};
